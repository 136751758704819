/* @refresh reload */

import { createForm, required, setValue } from '@modular-forms/solid'
import { debounce } from '@solid-primitives/scheduled'
import sanitizeHtml from 'sanitize-html'
import { customElement, noShadowDOM } from 'solid-element'
import { Component, createMemo, createResource, createSignal, For, onMount, Show, Suspense } from 'solid-js'
import Button from '../../shared/components/Button'
import initDatePicker from '../../shared/components/Datepicker'
import Modal from '../../shared/components/Modal'
import { SearchableItem } from '@/shared/components/SearchBar'
import { TripLastSelected } from '@/shared/models/efa/efa-trips-last-selected'
import * as EfaApi from '../../shared/models/efa/efa-api'
import { createFetch, withAbort } from '@solid-primitives/fetch'
import ModalSkeleton from '@/components/efa-teaser/subcomponents/ModalSkeleton'
import ComboSelect from '../../shared/components/ComboSelect'

type EfaForm = {
	// destination e.g. de:08111:6115
	destination: string
	// e.g. 23122022
	itdDateDayMonthYear: string
	itdTripDateTimeDepArr: string
	// e.g. 0812
	itdTime: string
	// e.g. de:08335:6554
	origin: string
}

type FormikString = {
	origin: string
	destination: string
	itdDateDayMonthYear?: string
	itdTime?: string
	itdTripDateTimeDepArr?: string
}

interface EfaTeaserHeaderProps {
	link: string
	header: string
	background: string
}

const EfaTeaserHeader: Component<EfaTeaserHeaderProps> = props => {
	noShadowDOM()

	const debounceTime = 300

	const [inMinutes, setInMinutes] = createSignal(0)

	const [fromQuery, setFromQuery] = createSignal<string | undefined>(undefined)
	const [toQuery, setToQuery] = createSignal<string | undefined>(undefined)

	const [lastFromItems, setLastFromItems] = createSignal<SearchableItem[]>([])
	const [lastToItems, setLastToItems] = createSignal<SearchableItem[]>([])

	const [fromResource] = createFetch<EfaApi.StopFinderResponse>(fromQuery, {}, [withAbort()])
	const [toResource] = createFetch<EfaApi.StopFinderResponse>(toQuery, {}, [withAbort()])

	const [needsInfo, setNeedsInfo] = createSignal(false)
	const [infoResource] = createResource(needsInfo, EfaApi.fetchInfos)

	const fromItems = createMemo<SearchableItem[]>(
		() =>
			fromResource()?.locations?.map(loc => {
				return { name: loc.name, id: loc.id }
			}) ?? lastFromItems()
	)

	const toItems = createMemo<SearchableItem[]>(
		() =>
			toResource()?.locations?.map(loc => {
				return { name: loc.name, id: loc.id }
			}) ?? lastToItems()
	)

	const toDebounced = debounce((query: string) => setToQuery(EfaApi.fetchStations(query)), debounceTime)
	const fromDebounced = debounce((query: string) => setFromQuery(EfaApi.fetchStations(query)), debounceTime)

	const [form, { Form, Field }] = createForm<EfaForm>({
		initialValues: {
			itdTripDateTimeDepArr: 'dep',
		},
	})

	const onFromSearch = (query: string) => {
		if (query.trim() === '') {
			return
		}
		fromDebounced(query)
	}

	const onToSearch = (query: string) => {
		if (query.trim() === '') {
			return
		}
		toDebounced(query)
	}

	const onSubmit = (values: EfaForm, event: Event) => {
		event.preventDefault()
		event.stopImmediatePropagation()

		const formik = Object.entries({
			origin: values.origin,
			destination: values.destination,
			itdDateDayMonthYear: values.itdDateDayMonthYear?.replaceAll('.', '')!,
			itdTime: values.itdTime?.replaceAll(':', '')!,
			itdTripDateTimeDepArr: values.itdTripDateTimeDepArr,
		} as FormikString)
			.map(([key, value]) => `${key}=${value}`)
			.join('&')

		const queryString = new URLSearchParams({
			formik: encodeURI(formik),
			lng: 'de',
		}).toString()

		window.location.href = `${props.link}/trip?${queryString}`
	}

	const addMinutes = (date: Date, minutes: number) => {
		return new Date(date.getTime() + minutes * 60000)
	}

	const onMinutesButtonClick = (minutes: number) => {
		setInMinutes(minutes)
		setValue(
			form,
			'itdTime',
			addMinutes(new Date(), minutes).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })
		)
	}
	const removeFocus = () => {
		const nowButton = document.getElementById('button-now') as HTMLInputElement
		nowButton.classList.remove('is-clicked')
	}
	const addFocus = () => {
		const nowButton = document.getElementById('button-now') as HTMLInputElement
		nowButton.classList.add('is-clicked')
	}

	onMount(() => {
		initDatePicker()
		onMinutesButtonClick(0)

		let tripsLastSelected: TripLastSelected[] = []

		try {
			// Load last selected values, which the efa app wrote to local storage
			tripsLastSelected = JSON.parse(localStorage.getItem('tripsLastSelected') ?? '[]')
		} catch (e) {
			console.warn('Warnung: ', e)
			tripsLastSelected = []
		}

		if (tripsLastSelected.length > 0) {
			const searchableItemsFrom: SearchableItem[] = tripsLastSelected
				.map(item => {
					if (!item.origin || !item.origin.name || !item.origin.id) {
						return undefined
					}
					return { name: item.origin.name, id: item.origin.id }
				})
				.filter(item => item !== undefined) as SearchableItem[]

			// Set only unique items with unique ids
			setLastFromItems(
				searchableItemsFrom.filter((item, index) => searchableItemsFrom.findIndex(i => i.id === item.id) === index)
			)

			const searchableItemsTo: SearchableItem[] = tripsLastSelected
				.map(item => {
					if (!item.destination || !item.destination.name || !item.destination.id) {
						return undefined
					}
					return { name: item.destination.name, id: item.destination.id }
				})
				.filter(item => item !== undefined) as SearchableItem[]

			setLastToItems(
				searchableItemsTo.filter((item, index) => searchableItemsTo.findIndex(i => i.id === item.id) === index)
			)
		}
	})

	const cleanHtml = (dirty: string) => {
		return sanitizeHtml(dirty, {
			transformTags: {
				a: function (tagName, attribs) {
					// My own custom magic goes here
					return {
						tagName: 'a',
						attribs: {
							href: attribs.href ?? '#',
							target: '_blank',
						},
					}
				},
			},
		})
	}

	return (
		<div class="" onMouseEnter={() => setNeedsInfo(true)}>
			<Form onSubmit={onSubmit} aria-label="Suchformular Fahrplanauskunft" class="efa-search-form js-efa-search-form">
				<h1 class="title">{props.header}</h1>
				<Modal
					header="Meldungen und Hinweise"
					onOpen={() => setNeedsInfo(true)}
					buttonContent={
						<span class="info">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								class="sl3p2937"
								viewBox="0 0 24 24"
								aria-hidden="true"
								style={{ display: 'block' }}>
								<path d="M11 9h2V7h-2m1 13c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m0-18A10 10 0 002 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2m-1 15h2v-6h-2v6z" />
							</svg>
							<span>Allgemeine Hinweise</span>
						</span>
					}>
					<Suspense fallback={<ModalSkeleton />}>
						<Show
							when={infoResource()?.infos.current ?? false}
							fallback={
								<div class="info-message">
									<h3>Keine aktuellen Informationen verfügbar</h3>
								</div>
							}>
							<For each={infoResource()?.infos.current}>
								{current => (
									<For each={current.infoLinks}>
										{info => (
											<div class="info-message">
												<h3>{info.title ?? info.subtitle ?? info.urlText}</h3>
												{/*eslint-disable-next-line solid/no-innerhtml */}
												<div innerHTML={cleanHtml(info.content ?? '')} />
											</div>
										)}
									</For>
								)}
							</For>
						</Show>
					</Suspense>
				</Modal>

				<div class="formbody">
					<input id="language" name="language" type="hidden" value="de" />

					<div class="efa-search-form-input efa-search-form-input--inline">
						<Field name="origin" type="string" validate={[required('Bitte Haltestelle wählen')]}>
							{field => (
								<ComboSelect
									items={fromItems()}
									inputLabel="Von"
									onSearch={onFromSearch}
									onSelect={item => setValue(form, field.name, item)}
									loading={fromResource.loading}
									error={field.error}
									openOnClick
									ariaLabel="Von"
								/>
							)}
						</Field>
					</div>

					<div class="efa-search-form-input efa-search-form-input--inline">
						<Field name="destination" type="string" validate={[required('Bitte Haltestelle wählen')]}>
							{field => (
								<ComboSelect
									items={toItems()}
									inputLabel="Nach"
									onSearch={onToSearch}
									onSelect={item => setValue(form, field.name, item)}
									loading={toResource.loading}
									error={field.error}
									openOnClick
									ariaLabel="Nach"
								/>
							)}
						</Field>
					</div>

					<div class="efa-search-form-group">
						<div class="form-radio-button-group" role="group" aria-label="Horizontale Button Gruppe">
							<span class="efa-search-form-radio-button">
								<Field name="itdTripDateTimeDepArr">
									{(field, fieldProps) => (
										<input
											{...fieldProps}
											id="use-departure-time"
											type="radio"
											value="dep"
											checked={field.value?.includes('dep')}
										/>
									)}
								</Field>
								<label for="use-departure-time">ABFAHRT</label>
							</span>
							<span class="efa-search-form-radio-button">
								<Field name="itdTripDateTimeDepArr">
									{(field, fieldProps) => (
										<input
											{...fieldProps}
											id="use-arrival-time"
											type="radio"
											value="arr"
											checked={field.value?.includes('arr')}
										/>
									)}
								</Field>
								<label for="use-arrival-time">ANKUNFT</label>
							</span>
						</div>
					</div>

					<div class="efa-search-form-group time-date-wrapper">
						<div class="form-button-group" role="group" aria-label="Horizontale Button Gruppe">
							<button
								classList={{ 'is-clicked': inMinutes() === 0 }}
								aria-label="Aktuelle Uhrzeit als Abfahrts- oder Ankunftszeit auswählen, Info: betätigen Sie die folgenden Schalter für weitere Abfahrtszeiten. Sie können wählen zwischen 30 oder 60 Minuten oder einer individuellen Abfahrts- oder Ankunftszeit."
								type="button"
								id="button-now"
								class="button button--secondary js-button-now button-now is-clicked"
								onClick={() => {
									onMinutesButtonClick(0)
									addFocus()
								}}>
								Jetzt
							</button>
							<button
								classList={{ 'is-clicked': inMinutes() === 30 }}
								type="button"
								class="button button--secondary js-button-in-30 button-in-30"
								onClick={() => onMinutesButtonClick(30)}>
								in 30 Min
							</button>
							<button
								classList={{ 'is-clicked': inMinutes() === 60 }}
								type="button"
								class="button button--secondary js-button-in-60 button-in-60"
								onClick={() => onMinutesButtonClick(60)}>
								in 60 Min
							</button>
						</div>
						<div class="efa-search-form-input efa-search-form-input--date efa-search-form-input--inline">
							<label for="search-date">Datum</label>
							<Field name="itdDateDayMonthYear">
								{(field, fieldProps) => (
									<input
										{...fieldProps}
										id="search-date"
										class="date-picker j-date-picker--end js-date form-control input"
										tabIndex="0"
										type="text"
										aria-label="Datum"
										value={field.value || ''}
										aria-required={true}
										onChange={removeFocus}
									/>
								)}
							</Field>
						</div>

						<div class="efa-search-form-input efa-search-form-input--time efa-search-form-input--inline">
							<label for="search-time">Uhrzeit</label>
							<Field name="itdTime">
								{(field, fieldProps) => (
									<input
										{...fieldProps}
										class="js-timeinput"
										type="time"
										size="8"
										value={field.value || ''}
										id="search-time"
										aria-required={true}
										onChange={removeFocus}
									/>
								)}
							</Field>
						</div>
					</div>

					<ul class="form-errors" />

					<div class="efa-search-form-group">
						<Button type="submit" aria-label="Jetzt Suche starten (Seite lädt neu)" text="Suchen" />

						<a class="link--advanced-search" href={props.link ?? '/sl3plus'}>
							Erweiterte Suche
						</a>
					</div>
				</div>
			</Form>
		</div>
	)
}

customElement(
	'efa-teaser-header',
	{
		link: '',
		header: '',
		background: '',
	},
	EfaTeaserHeader
)
